import {Component, OnInit} from '@angular/core'
import {HemnetService, IHemnetData} from '../services/hemnet.service'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {environment} from '../../environments/environment'
import {DisclaimerService} from '../services/disclaimer.service'
import {filter, first, switchMap} from 'rxjs/operators'

interface ICalculator {
  /**
   * ? Why are we calculating this?
   */
  loanAmount: number

  /**
   * Down payment, should we calculate that?
   */
  downPayment: number

  /**
   * What we get from Hemnet or something we calculate?
   */
  expenses: number

  /**
   * The interest rate as we find it as a number.
   */
  interestRate: number

  /**
   * The fee of a Bostadsrätt, if any.
   */
  avgift: number

  /**
   * Do not blame me...
   */
  houseType: 'Villa' | 'Bostadsrätt' | 'Fritidshus'
}

@Component({
  selector: 'spb-hemnet',
  templateUrl: './hemnet.component.html',
  styleUrls: ['./hemnet.component.scss']
})
export class HemnetComponent implements OnInit {
  /**
   * The disclaimer text from rt-backend
   */
  public disclaimerText = ''

  public utmTags: string = environment.utmTags

  protected calculatorUrl = environment.calculatorUrl
  public calculatorQuery: string = ''

  public home: IHemnetData = {} as any

  constructor(
    private disclaimerService: DisclaimerService,
    private hemnetService: HemnetService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      {
        next: (params: ParamMap) => {
          const id = params.get('id')
          if (id) {
            this.hemnetService.getHemnetData(id)
          }
        }
      })

    /**
     * Variables needed in getDisclaimer()
     */
    const periods = 480
    const amount = 1000000
    const startFee = 0
    let interestStartDate: number | null = null
    let interestRate: number | null = null

    /**
     * Get the home and the disclaimer text on the bottom
     */
    this.hemnetService.hemnetData.pipe(
      filter((hemnetData: IHemnetData) => hemnetData && !!hemnetData.interestStartDate), //only go ahead if interestStartDate exists
      first(),
      switchMap((i: IHemnetData) => {
        interestRate = Number(i.interestRate)
        interestStartDate = Number(i.interestStartDate)
        return this.disclaimerService.getDisclaimer(periods, amount, interestRate / 100, startFee, interestStartDate)
      })
    ).subscribe(
      {
        next: (disclaimerText: string) => {
          this.disclaimerText = disclaimerText
        }
      }
    )

    this.hemnetService.hemnetData.subscribe({
      next: (v) => this.buildCalculatorQuery(v)
    })

    this.hemnetService.hemnetData.subscribe({
      next: (v) => this.buildCalculatorQuery(v)
    })
  }

  private buildCalculatorQuery(i: IHemnetData): void {
    const typeMap: Record<number, string> = {
      0: 'Villa',
      1: 'Fritidshus',
      2: 'Bostadsrätt'
    }
    /**
     * NONE = -1,
     *   VILLA = 0,
     *   FRITIDSHUS = 1,
     *   BOSTADSRATT = 2
     */

    const calculator: ICalculator = {
      avgift: i.fee,
      downPayment: i.downPayment,
      expenses: Math.round((i.runCost ?? 0) / 12),
      houseType: typeMap[i.type as number] as any,
      interestRate: i.interestRate,
      loanAmount: i.askingPrice
    }

    /**
     * {
     *     "hemnetId": "20246155",
     *     "askingPrice": 1995000,
     *     "type": 0,
     *     "fee": 0,
     *     "runCost": 39989,
     *     "area": 120,
     *     "year": 1973,
     *     "name": "Hagagatan 12",
     *     "image": "https://bilder.hemnet.se/images/itemgallery_cut/24/9f/249ff86c802f9d05b882addb1a1b68f6.jpg",
     *     "downPayment": 299250,
     *     "expectedPrice": 1995000,
     *     "interestRate": 5.42,
     *     "interestStartDate": 1705276800000
     * }
     */
    this.calculatorQuery = new URLSearchParams(calculator as any).toString()
  }
}
