import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

import {AppComponent} from './app.component'
import {HemnetComponent} from './hemnet/hemnet.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AppRoutingModule} from './app-routing.module'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {HemnetCommonModule} from './common/common.module'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {ObjectInfoComponent} from './hemnet/object-info/object-info.component'
import {CalculationComponent} from './hemnet/calculation/calculation.component'
import {InputComponent} from './hemnet/input/input.component'
import {ReactiveFormsModule} from '@angular/forms'
import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
import {AuthInterceptor} from './application/auth-interceptor'

registerLocaleData(localeFr, 'fr', localeFrExtra)

@NgModule({
  declarations: [
    AppComponent,
    HemnetComponent,
    ObjectInfoComponent,
    CalculationComponent,
    InputComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    HemnetCommonModule,
    ReactiveFormsModule,
    ThemeModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
